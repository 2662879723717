@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("assets/fonts/sf-pro-text-regular.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("assets/fonts/sf-pro-text-regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("assets/fonts/sf-pro-text-medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("assets/fonts/sf-pro-text-semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("assets/fonts/sf-pro-text-bold.ttf") format("truetype");
  font-weight: 700;
}
